import React from 'react';

import './Splash.scss';
import { ticketsLink } from 'data/links';
import { Icon, LinkButton, CoverVideo } from 'components';

const Splash = () => {
    const videoSources = [
        { src: '/assets/video/promo-v2.mp4', type: 'video/mp4' }
    ];

    // TODO: this stuff
    const bottomLinks = [
        { url: 'http://miamibeach2020.groundupmusicfestival.com', name: '2020 Festival Site' },
        { url: 'http://miamibeach2019.groundupmusicfestival.com', name: '2019 Festival Site' },
        { url: 'http://miamibeach2018.groundupmusicfestival.com', name: '2018 Festival Site' },
        { url: 'http://miamibeach2017.groundupmusicfestival.com', name: '2017 Festival Site' }
    ];

    return (
        <CoverVideo sources={videoSources} aspectRatio={1.78}>
            <main className="splash-view">

                <img src="/assets/logo-2021.png" className="splash-logo"/>
                <div>
        {/*
                    <h1 className="_sm-hide fest-title">
                        GroundUP <span className="text-light">Music Festival</span>
                        <span className="text-regular"> 2021</span>
                    </h1>
                    <h2 className="md_-hide fest-title">
                        GroundUP <span className="text-light">Music Festival</span>
                        <span className="text-regular"> 2021</span>
                    </h2>
         */}
                    <div class="bloc">
                        <img src="/assets/2021-setting.png" className="_sm-hide splash-setting splash-setting-wide" alt="February 12-14, 2021 in Miami Beach, FL" />
                        <img src="/assets/2021-setting-tall.png" className="md_-hide splash-setting splash-setting-tall" alt="February 12-14, 2021 in Miami Beach, FL" />
                    </div>
                    <div class="bloc">
                        <img src="/assets/lineup-blindfaith.png" className="_sm-hide splash-setting splash-setting-wide" alt="Blind Faith lineup" />
                        <img src="/assets/lineup-blindfaith.png" className="md_-hide splash-setting splash-setting-tall" alt="Blind Faith lineup" />
                    </div>

                    <div className="_sm-hide bloc blind">
                        <h2 className="blind-faith text-regular">
                          Blind Faith discount available for a limited time
                        </h2>
                        <h3 className="blind-faith-sub text-regular">
                            Absolute lowest prices all year!
                        </h3>
                    </div>
                    <div className="md_-hide bloc blind">
                        <h3 className="blind-faith text-regular">
                          Blind Faith discount available for a limited time
                        </h3>
                        <h4 className="blind-faith-sub text-regular">
                            Absolute lowest prices all year!
                        </h4>
                    </div>


                    <LinkButton url={ticketsLink} className="button-dark">
                        <b>GET TICKETS <Icon className="icon-right" fa={'chevron-right'}/></b>
                    </LinkButton>

                     <div class="drag20 drop20">
                        <div class="bloc">
                            <span class="text-gold old-site-title text-regular">Past Year Festival Sites</span>
                        </div>
                        <div class="bloc">
                            <div class="old-site-group">
                                <a href="http://miamibeach2020.groundupmusicfestival.com" target="_blank" class="old-site text-shadow-block noul">2020</a><br/><Icon className="icon-right" fa={'chevron-down'}/>
                            </div>
                            <div class="old-site-group">
                                <a href="http://miamibeach2019.groundupmusicfestival.com" target="_blank" class="old-site text-shadow-block noul">2019</a><br/><Icon className="icon-right" fa={'chevron-down'}/>
                            </div>
                            <div class="old-site-group">
                                <a href="http://miamibeach2018.groundupmusicfestival.com" target="_blank" class="old-site text-shadow-block noul">2018</a><br/><Icon className="icon-right" fa={'chevron-down'}/>
                            </div>
                            <div class="old-site-group">
                                <a href="http://miamibeach2017.groundupmusicfestival.com" target="_blank" class="old-site text-shadow-block noul">2017</a><br/><Icon className="icon-right" fa={'chevron-down'}/>
                            </div>
                        </div>
                     </div>
                </div>
            </main>
        </CoverVideo>
 
    );
};

export default Splash;
