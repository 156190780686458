import './base.scss';
import 'mansion-ui';

import React from 'react';
import { render } from 'react-dom';

function mountApp (AppComponent, appProps = {}) {
  const rootElement = document.getElementById('app-root');

  return render(<AppComponent {...appProps}/>, rootElement);
}

import Splash from 'views/Splash';

mountApp(Splash);
