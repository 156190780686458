import React from 'react';

import Link from 'components/Link';
import { isFunction } from 'utils/type-utils';

const LinkButton = ({ url = null, className, onClick, linkClassName, children, replace }) => (
  <Link url={url} className={'link-button ' + (linkClassName || '')}>
    <button
      role="link"
      type="button"
      children={children}
      className={className || ''}
      onClick={(evt) => isFunction(onClick) ? onClick(evt) : null}
    />
  </Link>
);

export default LinkButton;
